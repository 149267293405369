<template>
  <div class="tickets">
    <div class="headTitle">
      <van-nav-bar  :title="titleName" :border='false'>
        <template #left>
          <van-icon :size="24" @click="$router.push('/')" color="#141212" name="arrow-left" slot="left" />
        </template>
      </van-nav-bar>
    </div>

    <div class="head">
      <div class="head_left">
        <van-dropdown-menu style="width:90px;box-shadow:none">
          <van-dropdown-item v-model="params.areaId" :options="citys" @change="search" />
        </van-dropdown-menu>
      </div>

      <div class="head_right">
        <van-search class="keyword_input" v-model="params.name" placeholder="输入目的地/景点名称" @search="search()" clearable />
      </div>
    </div>
    <div class="hot">
      <div class="hotTitle">
        热门精选
      </div>
      <div class="hotImg">
        <div class="hotImg_list" v-for="(item, index) in hots" :key="index" @click="toDetail(item.bizId,item.priceCalendarCount)">

          <van-image :src="item.cover">
            <template v-slot:error>加载失败</template>
          </van-image>
          <p>{{item.title}}</p>
          <!-- <p>
              <span>¥{{item.showPrice ? item.showPrice / 100 : 0}}</span>
              起
            </p> -->
        </div>
      </div>
    </div>
    <div class="tiket">
      <div class="tiket_title">为你推荐</div>
      <van-dropdown-menu v-if="false">
        <!-- 20200320应唐红要求暂时屏蔽按类型筛选 -->
        <van-dropdown-item v-model="dropdownValue.tag" :options="tags" @change="tagChange" v-if="false" />
        <van-dropdown-item v-model="dropdownValue.priceSort" :options="priceSort" @change="sortChange" />
        <van-dropdown-item v-model="dropdownValue.starSort" :options="starSort" @change="sortChange" />
      </van-dropdown-menu>
      <div class="tiket_list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list :immediate-check="false"
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            v-if="scenics && scenics.length > 0">
            <div class="tiket_list_item" v-for="item in scenics" :key="item.sellerId" @click="toDetail(item.sellerId,item.priceCalendarCount,item.priceCalendarCount,item)">

              <van-image :src="item.cover" class="ticket_img">
                <template v-slot:error>加载失败</template>
              </van-image>
              <div class="tiket_list_item_right">
                <p>{{item.name}}</p>
                <div class="tiket_list_item_right_text" v-if="false">
                  {{textFunc(item.remark)}}
                </div>

                <!--<div class="td_title_cont td_title_cont_hidden">{{item.remark}}</div>-->
                <div class="td_title_cont td_title_cont_hidden">{{item.introduce}}</div>

                <div class="t_l_order_btn_out cur_p d_f">
                  <div class="t_l_order_btn cur_p" v-if="item.priceCalendarCount>0 || item.sellerQrcodeUrl || item.sellerWebsite">可预订</div>
                  <template v-else>
                    <div class="t_l_order_btn cur_p orange" v-if="item.isFreeScenic == 1">开放式景区</div>
                  </template>
                  <div class="flex_1"></div>
                </div>
                <div class="tiket_list_item_right_img d_f ali_b">
                  <div class="flex_1 d_f ali_c tiket_list_item_right_img_distance">
                    <img src="./img/juli.png" alt="">
                    <span class="tiket_list_item_right_img_text" v-if="item.distance">距您 {{(item.distance/1000).toFixed(2)}}km</span>
                    <span class="tiket_list_item_right_img_text" v-else>距离加载失败</span>
                  </div>
                  <div class="item_price" v-if="item.isFreeScenic == 1"><span></span></div>
                  <div class="item_price" v-else><span>￥</span><span class="fs_18">{{item.showPrice ? item.showPrice/100 : 0}}</span><span>起</span></div>
                </div>
                <div class="tiket_list_item_right_rate" v-if="false">
                  <span v-if="item.grade">{{item.grade}}分</span>
                  <span v-else>暂无评分</span>
                  <p>
                    <span>￥{{item.showPrice ? item.showPrice/100 : 0}}</span>
                    <span>起</span>
                  </p>
                </div>
              </div>
            </div>
          </van-list>
          <div class='no_data_img' v-else></div>
        </van-pull-refresh>
      </div>
    </div>
    <!--<loginPop v-if="isShowLoginPop" @close="isShowLoginPop = false"></loginPop>-->
  </div>
</template>

<script src="./tickets.js">
</script>

<style  scoped src="./tickets.css"></style>
